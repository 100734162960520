import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ColorPickerModule } from './components/color-picker/color-picker.module';
import { DrawingComponent } from './components/drawing/drawing.component';
import { ErrorComponent } from './components/error/error.component';
import { HeaderComponent } from './components/header/header.component';
import { LiveTaggingControlsComponent } from './components/live-tagging-controls/live-tagging-controls.component';
import { ManualSchedulingFormComponent } from './components/manual-scheduling-form/manual-scheduling-form.component';
import { MatchDayComponent } from './components/match-day/match-day.component';
import { ChangeSrcMenuComponent } from './components/change-src-menu/change-src-menu.component';
import { CreatedTagsMoreComponent } from './components/created-tags-more/created-tags-more.component';
import { LoadingStateComponent } from './components/loading-state/loading-state.component';

import { MatchWidgetComponent } from './components/match-widget/match-widget.component';
import { MatchWidgetTeamComponent } from './components/match-widget/match-widget-team/match-widget-team.component';
import { AddToPlaylistModalComponent } from './components/modal/add-to-playlist-modal/add-to-playlist-modal.component';
import { CookiesModalComponent } from './components/modal/cookies-modal/cookies-modal.component';
import { RemuxVideoModalComponent } from './components/modal/remux-video-modal/remux-video-modal.component';
import { EditModalComponent } from './components/modal/edit-modal/edit-modal.component';
import { EditTagOffsetModalComponent } from './components/modal/edit-tag-offset-modal/edit-tag-offset-modal.component';
import { FirstTimeLoginModalComponent } from './components/modal/first-time-login-modal/first-time-login-modal.component';
import { LoginFailureModalComponent } from './components/modal/login-failure-modal/login-failure-modal.component';
import { LogoutModalComponent } from './components/modal/logout-modal/logout-modal.component';
import { NotAuthorizedModalComponent } from './components/modal/not-authorized-modal/not-authorized-modal.component';
import { PremiumFreeComponent } from './components/modal/premium-free/premium-free.component';
import { RemoveModalComponent } from './components/modal/remove-modal/remove-modal.component';
import { ShareModalComponent } from './components/modal/share-modal/share-modal.component';
import { ShareRecordingModalComponent } from './components/modal/share-recording-modal/share-recording-modal.component';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { NotificationModalModule } from './components/notification-modal/notification-modal.module';
import { PlaylistTitleComponent } from './components/playlist-title/playlist-title.component';
import { SearchComponent } from './components/search/search.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { TaggingPanelComponent } from './components/tagging-panel/tagging-panel.component';
import { TeamSearchComponent } from './components/team-search/team-search.component';
import { TreeComponent } from './components/tree/tree.component';
import { TrimmingPanelComponent } from './components/trimming-panel/trimming-panel.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoControlsComponent } from './components/video-player/video-controls.component';

import { VideoWidgetComponent } from './components/video-widget/video-widget.component';
import { ClearOnFocusDirective } from './directives/clear-on-focus.directive';
import { FluidInputDirective } from './directives/fluid-input.directive';
import { MaterialModule } from './material/material.module';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { VideoTaggingPanelComponent } from './components/video-tagging-panel/video-tagging-panel.component';
import { DefaultTermsOfUseComponent } from './components/default-terms-of-use/default-terms-of-use.component';
import { DefaultPrivacyCookiePolicyComponent } from './components/default-privacy-cookie-policy/default-privacy-cookie-policy.component';
import { CameraTranslatePipe } from './pipes/camera-translate.pipe';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { FakeFullscrDirective } from './directives/fake-fullscr.directive';
import { PwaInstallButtonComponent } from './components/pwa-install-button/pwa-install-button.component';
import { PwaInstallGuideModalComponent } from './components/modal/pwa-install-guide-modal/pwa-install-guide-modal.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { VideoPageLayoutComponent } from './components/video-page-layout/video-page-layout.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { CreatedTagsComponent } from './components/created-tags/created-tags.component';
import { DownloadVideoMenuComponent } from './components/download-video-menu/download-video-menu.component';
import { VideoControlPanelComponent } from './components/video-control-panel/video-control-panel.component';
import { AnnotationControlsComponent } from './components/annotation-controls/annotation-controls.component';
import { VideoPlayerWrapperComponent } from './components/video-player-wrapper/video-player-wrapper.component';
import { RestoreModalComponent } from './components/modal/restore-modal/restore-modal.component';
import { EmptyTrashModalComponent } from './components/modal/empty-trash-modal/empty-trash-modal.component';
import { SecondsToHoursPipe } from './pipes/seconds-to-hours.pipe';
import { BoolToYesNoPipe } from './pipes/bool-to-yes-no.pipe';
import { FreeSpaceVisualizerComponent } from './components/free-space-visualizer/free-space-visualizer.component';
import { RecordingInfoComponent } from './components/recording-info/recording-info.component';
import { DurationPipe } from './pipes/duration.pipe';
import { TimePipe } from './pipes/time.pipe';
import { DateFormat } from './pipes/date-format.pipe';
import { AppNewsModalComponent } from './components/modal/app-news-modal/app-news-modal.component';
import { MarkdownModule } from 'ngx-markdown';
import { VersionInfoComponent } from './components/version-info/version-info.component';
import { PtzComponent } from './components/ptz/ptz.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    ScrollingModule,
    MatDatepickerModule,
    NotificationModalModule,
    ColorPickerModule,
    MarkdownModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    MatDatepickerModule,
    MatchWidgetComponent,
    MatchWidgetTeamComponent,
    VideoWidgetComponent,
    PlaylistTitleComponent,
    SidebarComponent,
    SeparatorComponent,
    TaggingPanelComponent,
    HeaderComponent,
    CheckboxComponent,
    MatchDayComponent,
    TrimmingPanelComponent,
    MatDialogModule,
    DragDropModule,
    VideoPlayerComponent,
    VideoControlsComponent,
    TreeComponent,
    ManualSchedulingFormComponent,
    SafeStylePipe,
    AddToPlaylistModalComponent,
    RemoveModalComponent,
    RestoreModalComponent,
    EmptyTrashModalComponent,
    EditModalComponent,
    LogoutModalComponent,
    PremiumFreeComponent,
    SnackbarComponent,
    NotAuthorizedModalComponent,
    AppNewsModalComponent,
    LoginFailureModalComponent,
    ShareModalComponent,
    ShareRecordingModalComponent,
    CookiesModalComponent,
    EditTagOffsetModalComponent,
    ErrorComponent,
    TeamSearchComponent,
    LiveTaggingControlsComponent,
    SearchComponent,
    DefaultTermsOfUseComponent,
    DefaultPrivacyCookiePolicyComponent,
    FluidInputDirective,
    FakeFullscrDirective,
    PwaInstallButtonComponent,
    ChangeSrcMenuComponent,
    VideoPageLayoutComponent,
    PageLayoutComponent,
    CreatedTagsMoreComponent,
    CreatedTagsComponent,
    LoadingStateComponent,
    LoadingBarComponent,
    DownloadVideoMenuComponent,
    RecordingInfoComponent,
    VideoControlPanelComponent,
    AnnotationControlsComponent,
    VideoPlayerWrapperComponent,
    SecondsToHoursPipe,
    BoolToYesNoPipe,
    DurationPipe,
    TimePipe,
    DateFormat,
    FreeSpaceVisualizerComponent,
    MarkdownModule,
    VersionInfoComponent,
    PtzComponent,
  ],
  declarations: [
    MatchWidgetComponent,
    MatchWidgetTeamComponent,
    VideoWidgetComponent,
    PlaylistTitleComponent,
    SidebarComponent,
    SeparatorComponent,
    TaggingPanelComponent,
    HeaderComponent,
    CheckboxComponent,
    MatchDayComponent,
    TrimmingPanelComponent,
    TreeComponent,
    SafeStylePipe,
    AddToPlaylistModalComponent,
    RemoveModalComponent,
    RestoreModalComponent,
    EmptyTrashModalComponent,
    EditModalComponent,
    SnackbarComponent,
    LogoutModalComponent,
    PremiumFreeComponent,
    NotAuthorizedModalComponent,
    AppNewsModalComponent,
    LoginFailureModalComponent,
    RemuxVideoModalComponent,
    FirstTimeLoginModalComponent,
    VideoPlayerComponent,
    VideoControlsComponent,
    ErrorComponent,
    CookiesModalComponent,
    ShareModalComponent,
    ShareRecordingModalComponent,
    EditTagOffsetModalComponent,
    TeamSearchComponent,
    ManualSchedulingFormComponent,
    LiveTaggingControlsComponent,
    ClearOnFocusDirective,
    FluidInputDirective,
    FakeFullscrDirective,
    SearchComponent,
    NavigationItemComponent,
    DrawingComponent,
    VideoTaggingPanelComponent,
    DefaultTermsOfUseComponent,
    DefaultPrivacyCookiePolicyComponent,
    CameraTranslatePipe,
    SecondsToHoursPipe,
    BoolToYesNoPipe,
    DurationPipe,
    TimePipe,
    DateFormat,
    PwaInstallButtonComponent,
    PwaInstallGuideModalComponent,
    ChangeSrcMenuComponent,
    VideoPageLayoutComponent,
    PageLayoutComponent,
    CreatedTagsComponent,
    CreatedTagsMoreComponent,
    LoadingStateComponent,
    LoadingBarComponent,
    DownloadVideoMenuComponent,
    RecordingInfoComponent,
    VideoControlPanelComponent,
    AnnotationControlsComponent,
    VideoPlayerWrapperComponent,
    FreeSpaceVisualizerComponent,
    VersionInfoComponent,
    PtzComponent,
  ],
})
export class SharedModule {}
