import { AppState } from '../../models/app.state';
import { createSelector } from '@ngrx/store';

export const $videoPlayerState = (state: AppState) => state.videoPlayer;

export const $videoPlayerPlaying = createSelector(
  $videoPlayerState,
  ({ playing }) => playing,
);

export const $getVideoPlayerActualTime = createSelector(
  $videoPlayerState,
  ({ actualTime }) => actualTime,
);

export const $getVideoPlayerSpeed = createSelector(
  $videoPlayerState,
  ({ playerSpeed }) => playerSpeed,
);
