import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  Annotation,
  AnnotationTeam,
  AnnotationType,
  RecordingType,
} from '../../../../api/models';
import { fromEvent, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { $annotationIsLoading } from '../../store/selectors/annotation.selectors';
import {
  $annotationTypesEntities,
  $annotationTypesVideoTaggingPaged,
  $aTPagesVideoTaggingCountAsArray,
  $currentTaggingPageLeft,
  $currentTaggingPageRight,
  $nextPageLeftDisabled,
  $nextPageRightDisabled,
  $prevPageLeftDisabled,
  $prevPageRightDisabled,
} from '../../store/selectors/annotation-types.selectors';
import {
  TAGGING_NUMBERS,
  TAGS_PER_PAGE_VIDEO_TAGGING,
  VALID_EDITABLE_ELEMENTS,
} from '../../../app.constants';
import { AppState } from '../../models/app.state';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  changeATPageLeftAction,
  changeATPageRightAction,
  nextATPageLeftAction,
  nextATPageRightAction,
  prevATPageLeftAction,
  prevATPageRightAction,
} from '../../store/actions/annotation-types.actions';
import {
  animate,
  animateChild,
  group,
  keyframes,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { $currentRecordingUniversal } from '../../store/selectors/current-selections.selectors';
import { $videoTaggingPanelRolledUp } from '../../store/selectors/ui-flags.selectors';
import { toggleVideoTaggingPanelAction } from '../../store/actions/ui-flags.actions';

/* if animation states are defined as enum, this enum needs to be exported */
export enum OPENING_ANIMATION_STATES {
  OPEN = 'open',
  CLOSED = 'closed',
}
/* if animation states are defined as enum, this enum needs to be exported */
export enum FADE_ANIMATION_STATES {
  FADED = 'faded',
  DISPLAYED = 'displayed',
}

@Component({
  selector: 'cmv-video-tagging-panel',
  templateUrl: './video-tagging-panel.component.html',
  styleUrls: ['./video-tagging-panel.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        OPENING_ANIMATION_STATES.CLOSED,
        style({
          transform: 'translateY(-57px)',
        }),
      ),
      state(
        OPENING_ANIMATION_STATES.OPEN,
        style({
          transform: 'translateY(calc(-100% + 14px))',
          margin: 0,
        }),
      ),
      transition(
        `${OPENING_ANIMATION_STATES.OPEN} => ${OPENING_ANIMATION_STATES.CLOSED}`,
        [
          group([
            animate(
              '700ms ease',
              keyframes([
                style({
                  transform: 'translateY(calc(-100% + 14px))',
                  margin: '0',
                }),
                style({
                  transform: 'translateY(calc(-100% + 14px))',
                }),
                style({
                  transform: 'translateY(-57px)',
                }),
              ]),
            ),
            query('@fadeInOut', [animateChild()]),
          ]),
        ],
      ),
      transition(
        `${OPENING_ANIMATION_STATES.CLOSED} => ${OPENING_ANIMATION_STATES.OPEN}`,
        [
          group([
            animate(
              '700ms ease',
              keyframes([
                style({
                  transform: 'translateY(-57px)',
                }),
                style({
                  transform: 'translateY(calc(-100% + 14px))',
                }),
                style({
                  transform: 'translateY(calc(-100% + 14px))',
                  margin: '0',
                }),
              ]),
            ),
            query('@fadeInOut', [animateChild()]),
          ]),
        ],
      ),
    ]),
    trigger('fadeInOut', [
      state(
        FADE_ANIMATION_STATES.FADED,
        style({
          opacity: 0,
        }),
      ),
      state(
        FADE_ANIMATION_STATES.DISPLAYED,
        style({
          opacity: 1,
        }),
      ),
      transition(
        `${FADE_ANIMATION_STATES.FADED} => ${FADE_ANIMATION_STATES.DISPLAYED}`,
        animate('1000ms ease'),
      ),
      transition(
        `${FADE_ANIMATION_STATES.DISPLAYED} => ${FADE_ANIMATION_STATES.FADED}`,
        animate('300ms ease'),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoTaggingPanelComponent implements OnDestroy {
  @Output() tagCreated = new EventEmitter<Partial<Annotation>>();

  annotationTeam = AnnotationTeam;
  tags: AnnotationType[] = [];
  recordingType = RecordingType;
  tagsPerPage = TAGS_PER_PAGE_VIDEO_TAGGING;

  openingAnimationStates = OPENING_ANIMATION_STATES;
  fadeAnimationStates = FADE_ANIMATION_STATES;

  readonly match$ = this.store.pipe(select($currentRecordingUniversal));
  readonly taggingPanelRolledUp$ = this.store.pipe(
    select($videoTaggingPanelRolledUp),
  );
  readonly annotationIsLoading$ = this.store.pipe(select($annotationIsLoading));
  readonly currentPageLeft$ = this.store.pipe(select($currentTaggingPageLeft));
  readonly currentPageRight$ = this.store.pipe(
    select($currentTaggingPageRight),
  );
  readonly totalPages$ = this.store.pipe(
    select($aTPagesVideoTaggingCountAsArray),
  );
  readonly annotationTypesPaged$ = this.store.pipe(
    select($annotationTypesVideoTaggingPaged),
  );
  readonly nextPageLeftDisabled$ = this.store.pipe(
    select($nextPageLeftDisabled),
  );
  readonly prevPageLeftDisabled$ = this.store.pipe(
    select($prevPageLeftDisabled),
  );
  readonly nextPageRightDisabled$ = this.store.pipe(
    select($nextPageRightDisabled),
  );
  readonly prevPageRightDisabled$ = this.store.pipe(
    select($prevPageRightDisabled),
  );

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private readonly store: Store<AppState>) {
    fromEvent(window, 'keydown')
      .pipe(
        takeUntil(this.unsubscribe$.asObservable()),
        filter(() =>
          document.activeElement
            ? !VALID_EDITABLE_ELEMENTS.some(
                element => element === document.activeElement!.tagName,
              )
            : true,
        ),
        filter((event: KeyboardEvent) =>
          TAGGING_NUMBERS.some(key => key === event.key),
        ),
        tap(event => event.preventDefault()),
        map(event => ({
          index: Number(event.key) + 1,
          team: event.ctrlKey ? AnnotationTeam.AWAY : AnnotationTeam.HOME,
        })),
        withLatestFrom(this.store.pipe(select($annotationTypesEntities))),
        map(([event, annotations]) => {
          const annotation = annotations!.find(a => a.order === event.index);
          return annotation
            ? {
                annotation,
                team: event.team,
              }
            : null;
        }),
        filter(a => a !== null),
      )
      .subscribe(a => {
        this.onCreateTag(a!.annotation, a!.team);
      });
  }

  onCreateTag(selected: AnnotationType, team: AnnotationTeam): void {
    const annotation: Partial<Annotation> = {
      annotationTypeId: selected.id,
      annotationTypeName: selected.name,
      team,
    };

    this.tagCreated.emit(annotation);
  }

  setPageLeft(page: number): void {
    this.store.dispatch(changeATPageLeftAction({ page }));
  }

  selectPreviousLeftPage(): void {
    this.store.dispatch(prevATPageLeftAction());
  }

  selectNextLeftPage(): void {
    this.store.dispatch(nextATPageLeftAction());
  }

  setPageRight(page: number): void {
    this.store.dispatch(changeATPageRightAction({ page }));
  }

  selectPreviousRightPage(): void {
    this.store.dispatch(prevATPageRightAction());
  }

  selectNextRightPage(): void {
    this.store.dispatch(nextATPageRightAction());
  }

  toggleTaggingPanel(): void {
    this.store.dispatch(toggleVideoTaggingPanelAction());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
