import { createAction, props } from '@ngrx/store';

export const playVideoAction = createAction('PlayVideo');
export const playingVideoAction = createAction('PlayingVideo');
export const pauseVideoAction = createAction(
  'PauseVideo',
  props<{ actualTime: number }>(),
);
export const waitingVideoAction = createAction(
  'WaitingVideo',
  props<{ actualTime: number }>(),
);
export const fullscreenVideoAction = createAction('FullscreenVideo');
export const closeFullscreenAction = createAction('CloseFullscreen');
export const adjustVideoVolumeAction = createAction('AdjustVideoVolume');
export const scrubInVideoAction = createAction(
  'ScrubInVideo',
  props<{ actualTime: number }>(),
);
export const setVideoActualTimeAction = createAction(
  'SetVideoActualTime',
  props<{ actualTime: number }>(),
);
