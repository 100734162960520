import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  setPtzViewRequestAction,
  setPtzViewSuccessAction,
  showPtzRequestAction,
  showPtzSuccessAction,
} from '../actions/ptz.actions';
import { catchError, of, switchMap, withLatestFrom } from 'rxjs';
import { APIClient } from 'src/api';
import { ApiService } from 'src/app/services/api.service';
import { showSnackbarAction } from '../actions/snackbar.actions';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { $currentVideo } from '../selectors/current-selections.selectors';
import { $getVideoPlayerActualTime } from '../selectors/video.selectors';

@Injectable()
export class PtzEffects {
  showPtz$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showPtzRequestAction),
      switchMap(({ recordingId, source }) =>
        this.apiService
          .call(() =>
            this.apiClient.getPtzId({
              id: recordingId,
              source,
            }),
          )
          .pipe(
            switchMap(ptzInfo => {
              return of(
                ptzInfo.supported
                  ? showPtzSuccessAction({
                      manual: ptzInfo.manual,
                      view: ptzInfo.view,
                    })
                  : showSnackbarAction({
                      infoMessage: 'error.ptz.400',
                      icon: 'closing',
                    }),
              );
            }),
            catchError(error =>
              of(
                showSnackbarAction({
                  infoMessage: `error.ptz.${error.status}`,
                  icon: 'closing',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  setPtz$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPtzViewRequestAction),
      switchMap(({ manual, view, source, recordingId }) =>
        this.apiService.call(() =>
          this.apiClient
            .putPtzId({
              id: recordingId,
              source,
              body: {
                manual,
                goto: view,
              },
            })
            .pipe(
              withLatestFrom(
                this.store.select($currentVideo),
                this.store.select($getVideoPlayerActualTime),
              ),
              switchMap(([ptzInfo, currentVideo, actualTime]) => {
                const time = moment(ptzInfo.time).unix();

                return of(
                  setPtzViewSuccessAction({
                    manual,
                    view,
                    setAt: actualTime / 1000,
                    changeAt: time - currentVideo.startAt,
                  }),
                );
              }),
            ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
    private readonly store: Store<AppState>,
  ) {}
}
