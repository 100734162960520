import { select, Store } from '@ngrx/store';
import { AppState } from '../shared/models/app.state';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  $isFakeFullscreenActive,
  $isMenuCollapsed,
} from '../shared/store/selectors/ui-flags.selectors';

@Component({
  selector: 'cmv-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformComponent {
  fakeFullscreenActive$ = this.store.pipe(select($isFakeFullscreenActive));
  isMenuCollapsed$ = this.store.pipe(select($isMenuCollapsed));

  constructor(private readonly store: Store<AppState>) {}
}
