import { Action, createReducer, on } from '@ngrx/store';
import { UiFlagsState } from '../../models/app.state';
import {
  closeSearchingAfterRedirectAction,
  resetSearchingAction,
} from '../actions/filter-search.actions';
import {
  closeIOSPWAFullscreenWarningAction,
  closeMoreSubmenuAction,
  hideErrorOverlay,
  hideSearchFiltersUiAction,
  setClosedPWAButtonAction,
  setDrawingOverlayVisibilityAction,
  setFullscreenAction,
  setPWAAction,
  setSearchBarVisibilityAction,
  setVideoVisibleAction,
  showErrorOverlay,
  toggleMenuCollapse,
  toggleMoreSubmenuAction,
  toggleSearchBarAction,
  toggleSearchFiltersUiAction,
  toggleVideoTaggingPanelAction,
} from '../actions/ui-flags.actions';
import {
  fullscreenVideoAction,
  closeFullscreenAction,
} from '../actions/video.actions';
import { storeRouteAction } from '../actions/route.actions';
import { isIOS } from '../../../app.utils';

const initialState: UiFlagsState = {
  errorOverlayVisible: false,
  searchBarVisible: false,
  searchFiltersVisible: false,
  drawingOverlayVisible: false,
  videoFullscreen: false,
  enabledNativeFullscreen: !isIOS(),
  videoTaggingPanelRolledUp: false,
  videoVisible: false,
  closedIOSPWAFullscreenWarning:
    localStorage.getItem('closedIOSPWAFullscreenWarning') === 'true',
  closedPWAButton: localStorage.getItem('closedPWAButton') === 'true',
  isPWA: !window.matchMedia('(display-mode: browser)').matches,
  moreSubmenuOpen: false,
  isMenuCollapsed: sessionStorage.getItem('isMenuCollapsed') === 'true',
};

const reducer = createReducer(
  initialState,
  on(showErrorOverlay, state => ({
    ...state,
    errorOverlayVisible: true,
  })),
  on(hideErrorOverlay, state => ({
    ...state,
    errorOverlayVisible: false,
  })),
  on(toggleSearchBarAction, state => ({
    ...state,
    searchBarVisible: !state.searchBarVisible,
  })),
  on(setSearchBarVisibilityAction, (state, { visible, route }) => ({
    ...state,
    searchBarVisible: visible,
    searchFiltersVisible: state.searchFiltersVisible && !visible,
    routeBeforeSearch: route,
  })),
  on(toggleSearchFiltersUiAction, state => ({
    ...state,
    searchFiltersVisible: !state.searchFiltersVisible,
  })),
  on(hideSearchFiltersUiAction, state => ({
    ...state,
    searchFiltersVisible: false,
  })),
  on(closeSearchingAfterRedirectAction, state => ({
    ...state,
    searchBarVisible: false,
    searchFiltersVisible: false,
  })),
  on(resetSearchingAction, state => ({
    ...state,
    searchBarVisible: false,
    searchFiltersVisible: false,
  })),
  on(setDrawingOverlayVisibilityAction, (state, { visible }) => ({
    ...state,
    drawingOverlayVisible: visible,
  })),
  on(fullscreenVideoAction, state => ({
    ...state,
    videoFullScreen: true,
  })),
  on(toggleVideoTaggingPanelAction, state => ({
    ...state,
    videoTaggingPanelRolledUp: !state.videoTaggingPanelRolledUp,
  })),
  /* Hides video tagging panel when exiting full-screen */
  on(closeFullscreenAction, state => ({
    ...state,
    videoFullScreen: false,
    videoTaggingPanelRolledUp: false,
  })),
  /* Hides video tagging panel on navigate, because closeFullscreenActionAction is not dispatched when navigating from full screen */
  on(storeRouteAction, state => ({
    ...state,
    videoTaggingPanelRolledUp: false,
  })),
  on(setVideoVisibleAction, (state, { videoVisible }) => ({
    ...state,
    videoVisible,
  })),
  on(setFullscreenAction, (state, { videoFullscreen }) => ({
    ...state,
    videoFullscreen,
  })),
  on(closeIOSPWAFullscreenWarningAction, state => ({
    ...state,
    closedIOSPWAFullscreenWarning: true,
  })),
  on(setClosedPWAButtonAction, (state, { value }) => ({
    ...state,
    closedPWAButton: value,
  })),
  on(setPWAAction, (state, { value }) => ({
    ...state,
    isPWA: value,
  })),
  on(toggleMoreSubmenuAction, state => ({
    ...state,
    moreSubmenuOpen: !state.moreSubmenuOpen,
  })),
  on(closeMoreSubmenuAction, state => ({
    ...state,
    moreSubmenuOpen: false,
  })),
  on(toggleMenuCollapse, state => ({
    ...state,
    isMenuCollapsed: !state.isMenuCollapsed,
  })),
);

export function uiFlagsReducer(
  state: UiFlagsState,
  action: Action,
): UiFlagsState {
  return reducer(state, action);
}
