import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromEvent, merge, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AppState } from '../../models/app.state';
import { setDrawingOverlayVisibilityAction } from '../../store/actions/ui-flags.actions';
import { $colorPickerDimensions } from '../../store/selectors/responsivity.selectors';
import { $videoFullScreen } from '../../store/selectors/ui-flags.selectors';

const RESIZE_DELAY = 500;
const LINE_WIDTH = 5;
const TIME_BAR_HEIGHT = 0;
const WIDTH_RATIO = 16;
const HEIGHT_RATIO = 9;
const PRECISION = 3;
const RATIO_16_9 = Number((WIDTH_RATIO / HEIGHT_RATIO).toPrecision(PRECISION));

enum BLACK_BORDERS {
  NONE,
  SIDES,
  TOP_BOTTOM,
}

enum ShapeTypes {
  LINE,
  CIRCLE,
  FREE_HAND,
}

interface DrawingPoint {
  x: number;
  y: number;
  target?: boolean;
}

interface LineShape {
  startPoint: DrawingPoint;
  endPoint: DrawingPoint;
}

interface CircleShape {
  startPoint: DrawingPoint;
  endPoint: DrawingPoint;
}

interface FreeHandShape {
  points: DrawingPoint[];
}

interface DrawingShape {
  type: ShapeTypes;
  shape: LineShape | CircleShape | FreeHandShape;
  color: string;
}

interface CanvasDimensions {
  width: number;
  height: number;
  top: number;
  left: number;
}

/**
 * the layerX and layerY properties are deprecated so this is a hack-fix for the TS compiler
 */
interface ExtendedPointerEvent extends PointerEvent {
  layerX: number;
  layerY: number;
}

@Component({
  selector: 'cmv-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawingComponent implements AfterViewInit, OnDestroy {
  @ViewChild('canvas') canvas: ElementRef;
  private cx: CanvasRenderingContext2D;
  @Input() videoElementId: string;

  shapeTypes = ShapeTypes;
  currentShapeSelected: ShapeTypes = ShapeTypes.FREE_HAND;
  drawing: DrawingShape[] = [];
  isDrawing = false;
  currentColor = 'rgb(0, 0, 0)';

  private readonly panMove$ = new Subject<DrawingPoint>();
  private readonly panStart$ = new Subject<DrawingPoint>();
  private readonly panEnd$ = new Subject<DrawingPoint>();
  private readonly unsubscribe$ = new Subject<void>();

  readonly colorPickerDimensions$ = this.store.pipe(
    select($colorPickerDimensions),
  );

  canvasDimensions: CanvasDimensions = {
    top: 0,
    height: 0,
    left: 0,
    width: 0,
  };
  constructor(
    private readonly store: Store<AppState>,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    const canvasEl = this.canvas.nativeElement;
    this.cx = canvasEl.getContext(`2d`);

    merge(
      this.store.pipe(takeUntil(this.unsubscribe$), select($videoFullScreen)),
      fromEvent(window, 'resize'),
    )
      .pipe(
        takeUntil(this.unsubscribe$),
        delay(RESIZE_DELAY),
        map(() => document.getElementById(this.videoElementId)),
        map(element => element!.getBoundingClientRect()),
        distinctUntilChanged(
          (a, b) => a.height === b.height && a.width === b.width,
        ),
        map(({ width, height }) => this.computeCanvasProperties(width, height)),
      )
      .subscribe(a => {
        // TODO: Investigate race condition
        this.canvasDimensions = { ...a };
        this.cd.detectChanges();
        this.redraw();
      });
    this.panStart$.pipe(takeUntil(this.unsubscribe$)).subscribe(({ x, y }) => {
      this.isDrawing = true;
      this.cd.markForCheck();
      this.createShape({ x, y });
      this.redraw();
    });
    this.panEnd$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(({ target }) => (this.isDrawing = !!target)),
        filter(({ target }) => !!target),
      )
      .subscribe(({ x, y }) => {
        if (this.isDrawing) {
          this.isDrawing = false;
          this.cd.markForCheck();
          this.updateLastShape(this.drawing[this.drawing.length - 1], {
            x,
            y,
          });
          this.redraw();
        }
      });
    this.panMove$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(({ target }) => (this.isDrawing = !!target)),
        filter(({ target }) => !!target),
      )
      .subscribe(({ x, y }) => {
        if (this.isDrawing) {
          this.updateLastShape(this.drawing[this.drawing.length - 1], {
            x,
            y,
          });
        }
        this.redraw();
      });
  }

  panStart(event: HammerInput): void {
    const srcEvent = event.srcEvent as ExtendedPointerEvent;
    srcEvent.stopPropagation();
    srcEvent.preventDefault();
    this.panStart$.next({
      x: srcEvent.layerX,
      y: srcEvent.layerY,
      target: srcEvent.target === this.canvas.nativeElement,
    });
  }
  panMove(event: HammerInput): void {
    const srcEvent = event.srcEvent as ExtendedPointerEvent;
    srcEvent.stopPropagation();
    srcEvent.preventDefault();
    this.panMove$.next({
      x: srcEvent.layerX,
      y: srcEvent.layerY,
      target: srcEvent.target === this.canvas.nativeElement,
    });
  }
  panEnd(event: HammerInput): void {
    const srcEvent = event.srcEvent as ExtendedPointerEvent;
    srcEvent.stopPropagation();
    srcEvent.preventDefault();
    this.panEnd$.next({
      x: srcEvent.layerX,
      y: srcEvent.layerY,
      target: srcEvent.target === this.canvas.nativeElement,
    });
  }

  createShape(point: DrawingPoint): void {
    const normalizedCoordinates = this.normalizeCoordinates(
      point,
      this.canvasDimensions.width,
      this.canvasDimensions.height,
    );
    switch (this.currentShapeSelected) {
      case ShapeTypes.CIRCLE:
        this.drawing.push(
          this.createCircleShape(
            normalizedCoordinates,
            normalizedCoordinates,
            this.currentColor,
          ),
        );
        return;
      case ShapeTypes.LINE:
        this.drawing.push(
          this.createLineShape(
            normalizedCoordinates,
            normalizedCoordinates,
            this.currentColor,
          ),
        );
        return;
      case ShapeTypes.FREE_HAND:
        this.drawing.push(
          this.createFreeHandShape(normalizedCoordinates, this.currentColor),
        );
        return;
      default:
        return;
    }
  }

  updateLastShape(lastShape: DrawingShape, point: DrawingPoint): void {
    if (!lastShape) {
      return;
    }
    const normalizedCoordinates = this.normalizeCoordinates(
      point,
      this.canvasDimensions.width,
      this.canvasDimensions.height,
    );
    switch (lastShape.type) {
      case ShapeTypes.CIRCLE:
        const circle = lastShape.shape as CircleShape;
        this.drawing[this.drawing.length - 1] = this.createCircleShape(
          { x: circle.startPoint.x, y: circle.startPoint.y },
          normalizedCoordinates,
          lastShape.color,
        );
        return;
      case ShapeTypes.LINE:
        const line = lastShape.shape as LineShape;
        this.drawing[this.drawing.length - 1] = this.createLineShape(
          { x: line.startPoint.x, y: line.startPoint.y },
          normalizedCoordinates,
          lastShape.color,
        );
        return;
      case ShapeTypes.FREE_HAND:
        const freeHand = lastShape.shape as FreeHandShape;
        this.drawing[this.drawing.length - 1] = this.updateFreeHandShape(
          normalizedCoordinates,
          freeHand,
          lastShape.color,
        );
        return;
      default:
        return;
    }
  }

  createCircleShape(
    center: DrawingPoint,
    offsetPoint: DrawingPoint,
    color: string,
  ): DrawingShape {
    return {
      type: ShapeTypes.CIRCLE,
      shape: {
        startPoint: center,
        endPoint: offsetPoint,
      },
      color,
    };
  }

  createLineShape(
    startPoint: DrawingPoint,
    endPoint: DrawingPoint,
    color: string,
  ): DrawingShape {
    return {
      type: ShapeTypes.LINE,
      shape: {
        startPoint,
        endPoint,
      },
      color,
    };
  }

  createFreeHandShape(startPoint: DrawingPoint, color: string): DrawingShape {
    return {
      type: ShapeTypes.FREE_HAND,
      shape: {
        points: [startPoint],
      },
      color,
    };
  }

  updateFreeHandShape(
    point: DrawingPoint,
    { points }: FreeHandShape,
    color: string,
  ): DrawingShape {
    return {
      type: ShapeTypes.FREE_HAND,
      shape: {
        points: [...points, point],
      },
      color,
    };
  }

  drawCircleShape({ shape, color }: DrawingShape): void {
    const circle = shape as CircleShape;
    const denormalizedCoordinatesStart = this.denormalizeCoordinates(
      circle.startPoint,
      this.canvasDimensions.width,
      this.canvasDimensions.height,
    );
    const denormalizedCoordinatesEnd = this.denormalizeCoordinates(
      circle.endPoint,
      this.canvasDimensions.width,
      this.canvasDimensions.height,
    );
    this.cx.beginPath();
    this.cx.arc(
      denormalizedCoordinatesStart.x,
      denormalizedCoordinatesStart.y,
      /* Draw visible circle if endPoint is 0 */
      this.circleRadius(
        denormalizedCoordinatesStart,
        denormalizedCoordinatesEnd,
      ) || 1,
      0,
      Math.PI * 2,
      false,
    );
    this.cx.lineWidth = LINE_WIDTH;
    this.cx.strokeStyle = color;
    this.cx.stroke();
  }

  drawLineShape({ shape, color }: DrawingShape): void {
    const line = shape as LineShape;
    const denormalizedCoordinatesStart = this.denormalizeCoordinates(
      line.startPoint,
      this.canvasDimensions.width,
      this.canvasDimensions.height,
    );
    const denormalizedCoordinatesEnd = this.denormalizeCoordinates(
      line.endPoint,
      this.canvasDimensions.width,
      this.canvasDimensions.height,
    );
    this.cx.beginPath();
    this.cx.moveTo(
      denormalizedCoordinatesStart.x,
      denormalizedCoordinatesStart.y,
    );
    this.cx.lineTo(denormalizedCoordinatesEnd.x, denormalizedCoordinatesEnd.y);
    this.cx.lineCap = 'round';
    this.cx.lineWidth = LINE_WIDTH;
    this.cx.strokeStyle = color;
    this.cx.stroke();
  }

  drawFreeHandShape({ shape, color }: DrawingShape): void {
    const freeHand = shape as FreeHandShape;
    this.cx.lineWidth = LINE_WIDTH;
    this.cx.strokeStyle = color;

    freeHand.points.forEach((_, i) => {
      this.cx.beginPath();
      if (i) {
        const denormalizedCoordinatesStart = this.denormalizeCoordinates(
          freeHand.points[i - 1],
          this.canvasDimensions.width,
          this.canvasDimensions.height,
        );
        const denormalizedCoordinatesEnd = this.denormalizeCoordinates(
          freeHand.points[i],
          this.canvasDimensions.width,
          this.canvasDimensions.height,
        );
        this.cx.moveTo(
          denormalizedCoordinatesStart.x,
          denormalizedCoordinatesStart.y,
        );
        this.cx.lineTo(
          denormalizedCoordinatesEnd.x,
          denormalizedCoordinatesEnd.y,
        );
      } else {
        const denormalizedCoordinatesStart = this.denormalizeCoordinates(
          freeHand.points[0],
          this.canvasDimensions.width,
          this.canvasDimensions.height,
        );
        this.cx.moveTo(
          denormalizedCoordinatesStart.x,
          denormalizedCoordinatesStart.y,
        );
      }
      this.cx.closePath();
      this.cx.stroke();
    });
  }

  circleRadius(a: DrawingPoint, b: DrawingPoint): number {
    const v = { x: b.x - a.x, y: b.y - a.y };
    return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2));
  }

  redraw(): void {
    this.clearCanvas();
    this.drawing.forEach(dShape => {
      switch (dShape.type) {
        case ShapeTypes.CIRCLE:
          this.drawCircleShape(dShape);
          return;
        case ShapeTypes.LINE:
          this.drawLineShape(dShape);
          return;
        case ShapeTypes.FREE_HAND:
          this.drawFreeHandShape(dShape);
          return;
        default:
          return;
      }
    });
  }

  undo(): void {
    this.drawing.pop();
    this.redraw();
  }

  setColor(color: string): void {
    this.currentColor = color;
  }

  selectShapeType(sType: ShapeTypes): void {
    this.currentShapeSelected = sType;
  }

  clearCanvas(): void {
    this.cx.clearRect(0, 0, this.cx.canvas.width, this.cx.canvas.height);
  }

  closeDrawing(): void {
    this.store.dispatch(setDrawingOverlayVisibilityAction({ visible: false }));
  }

  computeRealVideoSize(
    type: BLACK_BORDERS,
    videoWidth: number,
    videoHeight: number,
  ): CanvasDimensions {
    switch (type) {
      case BLACK_BORDERS.SIDES:
        const width = (videoHeight * WIDTH_RATIO) / HEIGHT_RATIO;
        return {
          width,
          top: 0,
          left: (videoWidth - width) / 2,
          height: videoHeight,
        };
      case BLACK_BORDERS.TOP_BOTTOM:
        const height = (videoWidth * HEIGHT_RATIO) / WIDTH_RATIO;
        return {
          height,
          top: (videoHeight - height) / 2,
          left: 0,
          width: videoWidth,
        };
      default:
        return {
          top: 0,
          left: 0,
          height: videoHeight,
          width: videoWidth,
        };
    }
  }

  computeBlackBarsType(ratio: number): BLACK_BORDERS {
    switch (true) {
      case ratio === RATIO_16_9:
        return BLACK_BORDERS.NONE;
      case ratio > RATIO_16_9:
        return BLACK_BORDERS.SIDES;
      case ratio < RATIO_16_9:
        return BLACK_BORDERS.TOP_BOTTOM;
      default:
        return BLACK_BORDERS.NONE;
    }
  }

  computeCanvasProperties(width: number, height: number): CanvasDimensions {
    const ratio = Number((width / height).toPrecision(PRECISION));
    const roundedWidth = Math.ceil(width);
    const roundedHeight = Math.ceil(height);
    const blackBarType = this.computeBlackBarsType(ratio);
    const info = this.computeRealVideoSize(
      blackBarType,
      roundedWidth,
      roundedHeight,
    );
    const validateHeight = (computedHeight: number, originalHeight: number) =>
      computedHeight + TIME_BAR_HEIGHT > originalHeight
        ? computedHeight - TIME_BAR_HEIGHT
        : computedHeight;
    return {
      ...info,
      height: validateHeight(info.height, roundedHeight),
    };
  }

  normalizeCoordinates(
    { x, y }: DrawingPoint,
    width: number,
    height: number,
  ): DrawingPoint {
    return {
      x: x / width,
      y: y / height,
    };
  }

  denormalizeCoordinates(
    { x, y }: DrawingPoint,
    width: number,
    height: number,
  ): DrawingPoint {
    return {
      x: x * width,
      y: y * height,
    };
  }

  ngOnDestroy(): void {
    this.closeDrawing();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
