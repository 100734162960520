import { AnnotationTypesState, AppState } from '../../models/app.state';
import { createSelector } from '@ngrx/store';
import { AnnotationTypesPagingModel } from '../../models/annotation-types-paging.model';
import {
  TAGS_PER_PAGE,
  TAGS_PER_PAGE_VIDEO_TAGGING,
} from '../../../app.constants';
import { AnnotationType } from '../../../../api/models';

export const $annotationTypes = (state: AppState) => state.annotationTypes;

export const $annotationTypesEntities = createSelector(
  $annotationTypes,
  (state: AnnotationTypesState) =>
    state.entity != null
      ? [...state.entity].sort((a, b) => a.order - b.order)
      : null,
);

export const $annotationTypesLoaded = createSelector(
  $annotationTypes,
  (state: AnnotationTypesState) => state.loaded,
);

export const $annotationTypesLoading = createSelector(
  $annotationTypes,
  (state: AnnotationTypesState) => !state.loaded && state.loading,
);

export const $annotationTypeById = (id: string) =>
  createSelector(
    $annotationTypesEntities,
    (entities: AnnotationType[] | null) => {
      if (entities == null) {
        return null;
      }
      const foundEntity = entities.find(entity => entity.id === id);
      return foundEntity ? foundEntity : null;
    },
  );

/* METADATA */

export const $annotationTypesMetadata = createSelector(
  $annotationTypes,
  (state: AnnotationTypesState) => state.metadata,
);

export const $aTPagesCountAsArray = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => Array(paging.totalPages).fill(0),
);

export const $aTPagesVideoTaggingCountAsArray = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) =>
    Array(paging.totalPagesVideoTagging).fill(0),
);

export const $annotationTypesPaged = createSelector(
  $annotationTypesEntities,
  (entities: AnnotationType[] | null) => {
    if (entities == null) {
      return [];
    }
    const helpArray = Array(Math.ceil(entities.length / TAGS_PER_PAGE)).fill(0);
    return helpArray.map((_, i) =>
      entities.slice(i * TAGS_PER_PAGE, i * TAGS_PER_PAGE + TAGS_PER_PAGE),
    );
  },
);

export const $annotationTypesVideoTaggingPaged = createSelector(
  $annotationTypesEntities,
  (entities: AnnotationType[] | null) => {
    if (entities == null) {
      return [];
    }
    const helpArray = Array(
      Math.ceil(entities.length / TAGS_PER_PAGE_VIDEO_TAGGING),
    ).fill(0);
    return helpArray.map((_, i) =>
      entities.slice(
        i * TAGS_PER_PAGE_VIDEO_TAGGING,
        i * TAGS_PER_PAGE_VIDEO_TAGGING + TAGS_PER_PAGE_VIDEO_TAGGING,
      ),
    );
  },
);

export const $currentTaggingPage = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPage,
);

export const $currentTaggingPageLeft = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPageLeft,
);

export const $currentTaggingPageRight = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPageRight,
);

export const $nextPageDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) =>
    paging.currentPage === paging.totalPages - 1,
);

export const $prevPageDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPage === 0,
);

export const $nextPageLeftDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) =>
    paging.currentPageLeft === paging.totalPagesVideoTagging - 1,
);

export const $prevPageLeftDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPageLeft === 0,
);

export const $nextPageRightDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) =>
    paging.currentPageRight === paging.totalPagesVideoTagging - 1,
);

export const $prevPageRightDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPageRight === 0,
);
