<ng-container *ngIf="match$ | async as match">
  <div class="header-tagging">
    <ng-container *ngIf="match.teamHome">
      <div
        [style.background-image]="'url(' + match.teamHome.logo + ')'"
        class="club-logo"
      ></div>
      <span class="team-fullname">{{ getFullTeamName(match.teamHome) }}</span>
    </ng-container>
    <div class="cmv-h4 header-title-responsive-settings">
      {{ 'components.taggingPanel.addTags' | translate }}
    </div>
    <div class="team-name-spacer"></div>
    <ng-container *ngIf="match.teamAway">
      <span class="team-fullname">{{ getFullTeamName(match.teamAway) }}</span>
      <div
        [style.background-image]="'url(' + match.teamAway.logo + ')'"
        class="club-logo"
      ></div>
    </ng-container>
  </div>

  <div class="tab-group-wrapper">
    <mat-tab-group
      *ngIf="annotationTypesLoaded$ | async; else loadingState"
      class="tag-tabs"
      animationDuration="0ms"
      [selectedIndex]="currentPage$ | async"
    >
      <mat-tab
        *ngFor="
          let page of annotationTypesPaged$ | async;
          let pageIndex = index
        "
      >
        <div
          class="tagging-panel"
          [ngClass]="{ isLoading: annotationIsLoading$ | async }"
          (swipeleft)="selectNextPage()"
          (swiperight)="selectPreviousPage()"
        >
          <div class="outer-circle">
            <div class="inner-circle"></div>
          </div>
          <div class="separator"></div>
          <div class="left-side pitch-padding-played-match-xl">
            <div class="corner-top-left"></div>
            <div class="buttons">
              <div
                class="tag-button cmv-h5 tag-played-matches-xl"
                *ngFor="let tag of page; let tagIndex = index"
                (click)="onCreateTag(tag, annotationTeam.HOME)"
              >
                <div class="tag-button-text">
                  {{ tag.name }}
                </div>
                <ng-container
                  *ngIf="pageIndex * tagsPerPage + tagIndex + 1 as result"
                >
                  <div class="tag-button-shortcut" *ngIf="result - 1 < 10">
                    ({{ result - 1 }})
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="corner-bottom-left"></div>
          </div>
          <div class="right-side pitch-padding-played-match-xl">
            <div class="corner-top-right"></div>
            <div
              class="buttons right"
              *ngIf="match.type !== recordingType.TRAINING"
            >
              <div
                class="tag-button dark cmv-h5 tag-played-matches-xl"
                *ngFor="let tag of page; let tagIndex = index"
                (click)="onCreateTag(tag, annotationTeam.AWAY)"
              >
                <div class="tag-button-text">
                  {{ tag.name }}
                </div>
                <ng-container
                  *ngIf="pageIndex * tagsPerPage + tagIndex + 1 as result"
                >
                  <div class="tag-button-shortcut" *ngIf="result - 1 < 10">
                    (Ctrl + {{ result - 1 }})
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="corner-bottom-right"></div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <ng-template #loadingState>
      <cmv-loading-state></cmv-loading-state>
    </ng-template>
  </div>
  <div class="paging-wrap">
    <button
      class="paging-arrow-ripple"
      matRipple
      matRippleCentered="centered"
      [matRippleDisabled]="prevPageDisabled$ | async"
      [disabled]="prevPageDisabled$ | async"
      (click)="selectPreviousPage()"
    >
      <mat-icon
        class="cmv-dim-gray paging-arrow-icon"
        svgIcon="cmv-arrow-left"
      ></mat-icon>
    </button>

    <div class="page-mark-wrap">
      <div
        class="page-mark"
        *ngFor="let page of totalPages$ | async; let i = index"
        [ngClass]="{ 'page-mark-active': i === (currentPage$ | async) }"
        (click)="setPage(i)"
      ></div>
    </div>

    <button
      class="paging-arrow-ripple"
      matRipple
      matRippleCentered="centered"
      [matRippleDisabled]="nextPageDisabled$ | async"
      [disabled]="nextPageDisabled$ | async"
      (click)="selectNextPage()"
    >
      <mat-icon
        class="cmv-dim-gray paging-arrow-icon"
        svgIcon="cmv-arrow-right"
      ></mat-icon>
    </button>
  </div>
</ng-container>
