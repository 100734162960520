import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, from, of, tap } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { APIClient } from '../../../../api';
import { AppState } from '../../models/app.state';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  $currentRecordingVideos,
  $currentVideo,
  $getScrollPositions,
} from '../selectors/current-selections.selectors';
import {
  addRemuxVideoId,
  changeCurrentVideoAction,
  changeLiveTaggingModeAction,
  getCurrentSignedVideoFailureAction,
  getCurrentSignedVideoRequestAction,
  getCurrentSignedVideoSuccessAction,
  matchesTabIndexChanged,
  noVideoToSignAction,
  reactOnRemuxWsAction,
  removeRemuxVideoId,
  setCurrentLiveVideoAction,
  setCurrentVideoAction,
  setLiveTaggingModeAction,
  setScrollPosition,
} from '../actions/current-selections.actions';
import { getAnnotationsRecordingSuccessAction } from '../actions/annotation.actions';
import { LiveTaggingModes } from '../../models/current-selections.model';
import { ApiService } from 'src/app/services/api.service';
import { $userId } from '../selectors/user.selectors';
import { VideoStatus } from 'src/api/models';
import { getVideoRemuxSnackbarMessage, reportToGA } from 'src/app/app.utils';
import { showSnackbarAction } from '../actions/snackbar.actions';
import { GoogleAnalyticsEvent } from 'src/app/app.constants';
import { hidePtzRequestAction } from '../actions/ptz.actions';

@Injectable()
export class CurrentSelectionEffects {
  handleVideoChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        changeCurrentVideoAction,
        setCurrentVideoAction,
        setCurrentLiveVideoAction,
        getAnnotationsRecordingSuccessAction,
      ),
      switchMap(() => this.store.pipe(take(1), select($currentVideo))),
      switchMap(currentVideo => {
        sessionStorage.setItem('currentVideo', JSON.stringify(currentVideo));
        return of(hidePtzRequestAction(), getCurrentSignedVideoRequestAction());
      }),
    ),
  );

  changeCurrentVideo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeCurrentVideoAction),
        tap(({ inFullscreen }) => {
          reportToGA(GoogleAnalyticsEvent.CHANGE_VIDEO_SOURCE, {
            inFullscreen: inFullscreen,
          });
        }),
      ),
    { dispatch: false },
  );

  handleChangeLiveTaggingModeAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLiveTaggingModeAction),
      switchMap(({ mode }) => {
        sessionStorage.setItem('currentLiveTaggingMode', mode);
        return of(setLiveTaggingModeAction({ mode }));
      }),
    ),
  );

  handleChangeLiveTaggingModeActionStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLiveTaggingModeAction),
      filter(({ mode }) => mode === LiveTaggingModes.STREAM),
      withLatestFrom(this.store.select($currentRecordingVideos)),
      switchMap(([{}, videos]) =>
        from([
          setCurrentLiveVideoAction({
            videos,
          }),
        ]),
      ),
    ),
  );

  getSignedVideoUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentSignedVideoRequestAction),
      switchMap(() => this.store.pipe(take(1), select($currentVideo))),
      debounceTime(100),
      switchMap(currentVideo =>
        !currentVideo.id
          ? of(noVideoToSignAction())
          : this.apiService
              .call(() =>
                this.apiClient.getSigner({
                  recordingID: currentVideo.recordingId,
                  videoID: currentVideo.id,
                }),
              )
              .pipe(
                switchMap(({ stream }) =>
                  of(getCurrentSignedVideoSuccessAction({ stream })),
                ),
                catchError(error => {
                  const parsedError = {
                    type: error.status.toString(),
                    message: error.error.msg,
                  };

                  return of(
                    getCurrentSignedVideoFailureAction({
                      errors: [parsedError],
                    }),
                    handleErrorResponseAction({
                      errorType: parsedError.type,
                    }),
                  );
                }),
              ),
      ),
    ),
  );

  reactOnRemuxWs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reactOnRemuxWsAction),
      withLatestFrom(this.store.select($userId)),
      switchMap(([{ status, userId, videoId }, currentUserId]) => {
        if (userId === currentUserId) {
          if (status === VideoStatus.REMUXING) {
            return of(
              addRemuxVideoId({ videoId }),
              showSnackbarAction({
                infoMessage: getVideoRemuxSnackbarMessage(status),
              }),
            );
          } else if (
            status === VideoStatus.FAILED ||
            status === VideoStatus.DONE
          ) {
            return of(
              removeRemuxVideoId({ videoId }),
              showSnackbarAction({
                infoMessage: getVideoRemuxSnackbarMessage(status),
              }),
            );
          }
        }
        return EMPTY;
      }),
    ),
  );

  matchesTabIndexChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(matchesTabIndexChanged),
        tap(({ index }) => {
          sessionStorage.setItem('matchesTabIndex', index.toString());
        }),
      ),
    { dispatch: false },
  );

  setScrollPosition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setScrollPosition),
        withLatestFrom(this.store.select($getScrollPositions)),
        tap(([_, positions]) =>
          sessionStorage.setItem('scrollPositions', JSON.stringify(positions)),
        ),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
  ) {}
}
